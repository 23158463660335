const styles = (theme) => {
  const { baseUnit, typography, palette } = theme;

  return {
    // Default size is medium - 44px
    root: {
      ...typography.body1,
      backgroundColor: '#FFF',
      border: `1px solid ${palette.border.secondary}`,
      borderRadius: 4,
      padding: 9, // 10px - 1px border
      width: '100%',
      transition: 'all 200ms ease-out',
      '&:hover': {
        borderColor: palette.border.hover,
      },
    },
    // small size - 32px
    small: {
      padding: '3px 9px',
    },
    // large size - 52px
    large: {
      ...typography.h4,
      fontWeight: 'normal',
      padding: '11px 9px',
    },
    input: {
      height: 'auto',
      minHeight: baseUnit * 4,
      padding: `${baseUnit}px 0`,
      '&::-webkit-input-placeholder': {
        color: palette.text.secondary,
      },
      '&::moz-placeholder': {
        color: palette.text.secondary,
      },
      '&::-ms-input-placeholder': {
        color: palette.text.secondary,
      },
      '&::placeholder': {
        color: palette.text.secondary,
      },
    },
    multiLine: {
      lineHeight: '1.35',
    },
    disabled: {
      '&&': {
        backgroundColor: palette.background.secondary,
        '&:hover': {
          borderColor: palette.border.secondary,
        },
        '&::-webkit-input-placeholder': {
          color: palette.text.disabled,
        },
        '&::moz-placeholder': {
          color: palette.text.disabled,
        },
        '&::-ms-input-placeholder': {
          color: palette.text.disabled,
        },
        '&::placeholder': {
          color: palette.text.disabled,
        },
      },
    },
    focused: {
      '&&': {
        borderColor: palette.primary.main,
        '&:hover': {
          borderColor: palette.primary.main,
        },
      },
    },
    error: {
      '&&': {
        backgroundColor: palette.status.red[5],
        borderColor: palette.status.red[0],
        '&:hover': {
          borderColor: palette.status.red[0],
        },
      },
    },
  };
};

export default styles;
