const styles = (theme) => {
  const { baseUnit, palette } = theme;

  return {
    // Default size is medium - 44px
    root: {
      listStyleType: 'none',
      padding: 0,
      margin: 0,
    },
    start: {
      marginLeft: -baseUnit * 2,
    },
    end: {
      marginRight: baseUnit - 9, // input padding - desired margin on the right
    },
    item: {
      alignItems: 'center',
      display: 'flex',
      margin: `0 ${baseUnit / 2}px`,
    },
    text: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'center',
      minWidth: baseUnit * 6, // mimic icon size
      justifyContent: 'center',
      marginTop: '1px', // center with input text
    },
    icon: {
      color: palette.icons.gray,
    },
    disabledIcon: {
      color: palette.text.disabled,
    },
  };
};

export default styles;
